const supportsHistory = !!(window.history && window.history.pushState)

const ee = require('event-emitter')

function eeEvents (el) {
  let events = ['click', 'transitionend']

  ee(el)

  events.forEach((event) => {
    el.addEventListener(event, (e) => { el.emit(event, e) })
  })
}

module.exports = {
  supportsHistory,
  eeEvents
}
