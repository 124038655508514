const { supportsHistory } = require('./lib/utils')

class Category {
  constructor (el, app) {
    let url = el.getAttribute('href')

    Object.assign(this, {
      el,
      url,
      app
    })

    this.addEventListeners()
  }

  addEventListeners () {
    let { el, url, app } = this

    el.addEventListener('click', (e) => {
      if (!supportsHistory || el.getAttribute('rel') === 'external') {
        return
      }

      window.history.pushState(null, null, url)
      app.route()
      e.preventDefault()
    })
  }
}

module.exports = Category
