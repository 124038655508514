function getDisplay (el) {
  return window.getComputedStyle(el).getPropertyValue('display')
}

function displayNone (e) {
  this.style.display = 'none'
}

function hide (el) {
  el.addEventListener('transitionend', displayNone)
  el.addEventListener('webkitTransitionEnd', displayNone)

  el.classList.add('hide')
  el.classList.remove('show')

  let display = getDisplay(el)

  return new Promise((resolve, reject) => {
    if (display === 'none') {
      resolve()
    } else {
      el.addEventListener('transitionend', resolve)
      el.addEventListener('webkitTransitionEnd', resolve)
    }
  })
}

function show (el) {
  // Remove hide listener
  el.removeEventListener('transitionend', displayNone)
  el.removeEventListener('webkitTransitionEnd', displayNone)

  let display = getDisplay(el)

  if (display === 'none') {
    if (el.style.display) {
      el.style.display = null
    } else {
      el.style.display = 'initial'
    }
  }

  el.classList.remove('hide')
  window.setTimeout(() => {
    el.classList.add('show')
  }, 10)
}

function filterDOM (el, filter) {
  while (el !== document && !el.matches(filter)) {
    el = el.parentNode
  }
  if (el === document) return

  return el
}

module.exports = {
  hide,
  show,
  filterDOM
}
