const defined = require('defined')
const ee = require('event-emitter')
const { eeEvents } = require('./lib/utils')

const fs = require('fs')
const source = fs.readFileSync(__dirname + '/views/modal.hbs', 'utf-8') // eslint-disable-line no-path-concat

class Modal {
  constructor (opt = {}) {
    this.template = source
    this.onhidden = []
    this.disableClose = false

    this.id = 1

    this.context = {}

    ee(this)

    Object.assign(this, opt)
  }

  render (context = this.context) {
    context.id = this.id

    return this.template.replace(/{{\s+id\s+}}/, context.id)
  }

  set hideClose (value) {
    this._hideClose = !!value

    if (value) {
      this.closeBtn.style.display = 'none'
    } else {
      this.closeBtn.style.display = null
    }
  }

  get hideClose () {
    return this._hideClose
  }

  addEventListeners (parent) {
    parent = defined(parent, this.overlay)
    eeEvents(this.overlay)
    parent.addEventListener('click', this.click.bind(this))
  }

  click (e) {
    if (this.disableClose) {
      return
    }

    if (e.target === this.closeBtn ||
      this.closeBtn.contains(e.target)) {
      this.hide()
    } else if (e.target === this.modalWrapper) {
      this.hide()
    }

    e.preventDefault()
    e.stopPropagation()
  }

  get overlay () {
    return document.getElementById(`overlay-${this.id}`)
  }

  get closeBtn () {
    return this.overlay.querySelector('.close')
  }

  get modalWrapper () {
    return this.overlay.querySelector('.modal-wrapper')
  }

  get modalContent () {
    return this.overlay.querySelector('.modal-content')
  }

  show (html) {
    this.modalContent.innerHTML = defined(html, this.modalContent.innerHTML)

    this.overlay.style.display = 'table'
    let onShow = (e) => {
      if (e.propertyName === 'opacity') {
        this.emit('show')
        this.overlay.off('transitionend', onShow)
      }
    }
    this.overlay.on('transitionend', onShow)

    window.setTimeout(() => {
      this.overlay.classList.add('show')
    }, 100)
  }

  hide () {
    this.overlay.classList.remove('show')
    window.setTimeout(() => {
      this.overlay.style.display = null
      this.emit('hide')
    }, 300)
  }
}

module.exports = Modal
