const Flat = require('@lejeunerenard/flat/client')
const Modal = require('./modal')

const Category = require('./category')
const { supportsHistory } = require('./lib/utils')
const { filterDOM } = require('./lib/dom')

class App {
  constructor () {
    this.flat = new Flat()

    this.categories = [...this.content.querySelectorAll('.categories')]
      .map((el) => {
        return new Category(el, this)
      })

    // Ensure Category is registered
    this.content.addEventListener('click', (e) => {
      let el = filterDOM(e.target, '.categories')

      // Skip if not a category or already registered
      if (!el || this.categories.filter((cat) => cat.el === el).length) {
        return
      }

      this.categories.push(new Category(el, this))

      el.click()

      e.stopPropagation()
      e.preventDefault()
    }, true)

    this.setupNavListeners()

    window.addEventListener('resize', () => {
    })

    this.setupGallery()

    if (supportsHistory) {
      window.addEventListener('popstate', this.route.bind(this))
    }
  }

  get content () {
    return document.getElementById('content')
  }

  routeClick (filter, e) {
    let t = filter
      ? filterDOM(e.target, filter) : e.target

    if (!(supportsHistory && t)) {
      return
    }

    let url = t.getAttribute('href')

    window.history.pushState(null, null, url)
    this.route()
    e.preventDefault()
  }

  setupNavListeners () {
    let nav = document.getElementById('nav')
    nav.addEventListener('click', this.routeClick.bind(this, 'ul li a'))

    document.getElementById('ljr_small')
      .addEventListener('click', this.routeClick.bind(this, 'a'))
  }

  route () {
    let path = window.location.pathname

    // Set default path
    if (path === '/' || !path) {
      path = '/index.html'
    }

    if (!path.match(/\.html$/)) {
      path += '.html'
    }
    let content = this.flat.getContent(path)

    if (content) {
      this.flat.get(path).then((html) => {
        this.content.innerHTML = html
      })
    }

    let navs = document.querySelectorAll('#nav ul li a')
    let currentNav = [...navs].filter((nav) => path.match(nav.getAttribute('href')))[0]
    this.setNav(currentNav)
  }

  setNav (current) {
    let navLinks = document.querySelectorAll('#nav ul li a')
    for (let i = 0; i < navLinks.length; i++) {
      let link = navLinks[i]
      if (link === current) {
        continue
      }

      link.classList.remove('current')
    }

    if (current) {
      current.classList.add('current')
    }
  }

  setupGallery () {
    let modal = new Modal()

    let modalHTML = modal.render()
    document.body.insertAdjacentHTML('beforeend', modalHTML)

    modal.addEventListeners()

    document.body.addEventListener('click', (e) => {
      let target = filterDOM(e.target, '.cat_pic')
      if (!target) return

      let imgURL = target.getAttribute('href')
      modal.show(`<img src="${imgURL}">`)

      e.preventDefault()
    })
  }
}

module.exports = App
